import React, { Component } from 'react';
import axios from 'axios';

import { InputMikrotikPair, ResponceMikrotikPair, ServiceState } from "../services/api.interfaces";

import InputGroup from 'react-bootstrap/InputGroup';
import { Card, Button, Form, Alert, Spinner } from 'react-bootstrap';
import GetUserStorage from '../services/utils/user_storage';
import { ApiContext } from '../services/api.service';

import { Table, Steps } from 'antd';

import { ProxyColumns } from './proxy_colums';

import ProxyEditPage from './ProxyEditPage';

import FileSaver from 'file-saver';



const { Step } = Steps;

const R_PAGES = {
  START_PAGE: -1,
  SERVER_LOGIN: 0,
  ROUTER_LOGIN: 1,
  WAIT_PAGE: 2,

  PROXY_PAGE: 3,
  PROXY_EDIT_PAGE: 4
}

export class MainPage extends Component {

  public state: any;
  private columns: any;

  constructor(props: any) {
    super(props);
    this.state = {
      page: R_PAGES.START_PAGE,

      local: {
        url: 'http://localhost:50019',
        ping: false,
        state: null
      },

      run_local: false,

      user: 'admin',
      password: '',
      router_ip: '192.168.88.1',

      server_ip: '',
      is_logged: false,

      alert: { variant: 'warning', text: 'UNKNOWN' },
      proxy_map_dhcp: null,

    }

    this.columns = ProxyColumns;

    this.NextPage = this.NextPage.bind(this);
    this.OnStart = this.OnStart.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRouterIp = this.onChangeRouterIp.bind(this);
    this.onChangeServer = this.onChangeServer.bind(this);
    this.OnLogin = this.OnLogin.bind(this);
    this.OnRouterLogin = this.OnRouterLogin.bind(this);
    this.UpdateStatus = this.UpdateStatus.bind(this);
    this.GetProxyMap = this.GetProxyMap.bind(this);

    this.onChangeLocalServer = this.onChangeLocalServer.bind(this);
    this.OnLocalServerStatus = this.OnLocalServerStatus.bind(this);

    this.UpdateServer = this.UpdateServer.bind(this);
    this.EditProxy = this.EditProxy.bind(this);
    this.SaveProxy = this.SaveProxy.bind(this);

    //this.handleDelete =  this.handleDelete.bind(this);

    this.CancelEditProxy = this.CancelEditProxy.bind(this);
    this.saveFile = this.saveFile.bind(this);
  }


  saveFile = () => {
    FileSaver.saveAs(
      process.env.PUBLIC_URL + "/files/rp_server-win.exe",
      "rp_server-win.exe"
  );
 }

  onChangeLocalServer = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ local: { url: e.target.value } });
  };

  async UpdateServer() {
    ApiContext.UpdateServer();
  }

  async OnLocalServerStatus() {
    var self = this;
    const status_api = `${this.state.local.url}/api/status`

    axios.get(status_api)
      .then(res => {
        self.setState({ local: { ping: true, state: res.data, url: self.state.local.url } });
      })
      .catch(err => {
        self.setState({ local: { ping: false, state: null, url: self.state.local.url } });
      })
  }

  async UpdateStatus() {

    if (this.state.page === R_PAGES.PROXY_EDIT_PAGE) {
      return;
    }

    const resonce = await ApiContext.UpdateCurrState();
    if (this.state.run_local === true && this.state.local.ping === false) {
      this.setState({ page: R_PAGES.WAIT_PAGE, alert: { variant: 'info', text: 'Waiting LocalServer' } });
      return;
    }

    if (this.state.run_local === true) {

      await this.OnLocalServerStatus();

      const ls_state = this.state.local.state;
      console.log(ls_state)
      if (ls_state.invalid_login === true) {
        this.setState({ page: R_PAGES.ROUTER_LOGIN, alert: { variant: 'danger', text: 'Invalid Creds' }, run_local: true });
        return;
      }
    }

    const server_status = resonce.server_status;

    if (server_status === ServiceState.SERVER_UNREABLE) {
      this.setState({ page: R_PAGES.SERVER_LOGIN, alert: { variant: 'danger', text: server_status } });
      return;
    }

    if (server_status === ServiceState.PROCESS_SYNC_WIREGUARD) {
      this.setState({ page: R_PAGES.WAIT_PAGE, alert: { variant: 'info', text: 'Waiting Sync' } });
      return;
    }



    if (server_status === ServiceState.CONFIGURE_ROUTER) {
      this.setState({ page: R_PAGES.ROUTER_LOGIN, alert: { variant: 'info', text: server_status }, run_local: true });
      return;
    }

    if ((this.state.proxy_map_dhcp === null) && (server_status === ServiceState.READY)) {
      await this.GetProxyMap();
    }
    if ((server_status === ServiceState.EMPTY_PROXY) || (server_status === ServiceState.READY)) {
      this.setState({ page: R_PAGES.PROXY_PAGE, alert: { variant: 'info', text: server_status }, run_local: false });
      return;
    }

    // this.setState({ /*page: R_PAGES.PROXY_PAGE,*/ alert: {variant: 'secondary', text: server_status } });
    return;
  }


  onChangeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ user: e.target.value });
  };

  onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  onChangeRouterIp = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ router_ip: e.target.value });
  };

  onChangeServer = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    this.setState({ server_ip: e.target.value });
  };

  NextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  OnStart() {
    this.setState({ page: this.state.page + 1 });
  }

  componentDidMount() {

    var user_storage = GetUserStorage();

    if (user_storage?.server.server_ip != '') {
      ApiContext.OnServerLogin(
        user_storage?.server.email,
        user_storage?.server.token,
        user_storage?.server.server_ip);
    }

    setInterval(this.UpdateStatus.bind(this), 2000);
  }

  //GET_PROXY_MAP

  async GetProxyMap() {
    const res = await ApiContext.GetProxyMap();
    this.setState({ proxy_map_dhcp: res });
  }

  async OnRouterLogin() {
    const wgc = await ApiContext.GetWGCredentials(this.state.user, this.state.password, this.state.router_ip);

    const data: InputMikrotikPair = {
      wgc,
      mkc: {
        ip: this.state.router_ip,
        login: this.state.user,
        pass: this.state.password
      }
    }

    var self = this;
    const status_api = `${this.state.local.url}/api/router_pair`
    axios.post(status_api, data)
      .then(res => {
        const result: ResponceMikrotikPair = res.data;
        if (result.state === true) {
          ApiContext.SetClientPeer(result.interface!.publicKey, result.interface!.allowedIPs);
        }
      })
      .catch(err => {
        console.log("OnRouterLogin:", err)
      })
  }


  async OnLogin() {

    var user_storage = GetUserStorage();

    const connected = await ApiContext.OnServerLogin(user_storage?.server.email, user_storage?.server.token, this.state.server_ip);

    if (connected === true) {
      user_storage!.server.server_ip = this.state.server_ip;
      localStorage.setItem(
        'user',
        JSON.stringify(user_storage)
      );
    }
  }

  async EditProxy() {
    await ApiContext.GetServerProxy();
    this.setState({ page: R_PAGES.PROXY_EDIT_PAGE });
  }

  async SaveProxy() {

    await ApiContext.UpdateProxyMap();
    await this.GetProxyMap();
    this.setState({ page: R_PAGES.PROXY_PAGE });
  }

  async CancelEditProxy() {
    await this.GetProxyMap();
    this.setState({ page: R_PAGES.PROXY_PAGE });
  }


  render() {

    return (
      <Card className="text-center">
        {this.state.run_local == true ? (
          <InputGroup hasValidation>
            <Button onClick={this.saveFile} variant="primary">Download LocalServer</Button>
            <Button onClick={this.OnLocalServerStatus} variant={this.state.local.ping ? "success" : "danger"}>LocalServerConnect</Button>
            <Form.Control
              type="text"
              placeholder="http://localhost:50019"
              aria-describedby="inputGroupPrepend"
              name="username"
              value={this.state.local.url}
              onChange={this.onChangeLocalServer}
              isInvalid={!this.state.local.ping}
            />
          </InputGroup>) : null}

        <Card.Body>
          <Steps current={this.state.page}>
            <Step title="ServerLogin" />
            <Step title="MikrotikPair" />
            <Step title="ProxyMap" />
          </Steps>
          <Card.Body>
            {this.state.page == R_PAGES.WAIT_PAGE ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : null}

            {this.state.page == R_PAGES.START_PAGE ? (<Button onClick={this.OnStart}>Start</Button>) : null}

            {this.state.page == R_PAGES.SERVER_LOGIN ? (
              <Form.Group className="mb-3">
                <Card.Text>
                  <Form.Control
                    type="text"
                    placeholder="ServerIp"
                    value={this.state.server_ip}
                    onChange={this.onChangeServer}
                  /></Card.Text>
              </Form.Group>) : null}
            {this.state.page == R_PAGES.ROUTER_LOGIN ? (
              <Form.Group className="mb-3">
                <Card.Text>
                  <Form.Control
                    type="text"
                    placeholder="192.168.88.1"
                    value={this.state.router_ip}
                    onChange={this.onChangeRouterIp}
                  />
                  
                </Card.Text>

                <Card.Text>
                  <Form.Control
                    type="text"
                    placeholder="admin"
                    value={this.state.user}
                    onChange={this.onChangeUser}
                  />
                </Card.Text>

                <Card.Text>
                  <Form.Control
                    type="password"
                    placeholder="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                  />
                </Card.Text>
              </Form.Group>
            ) : null}

            {this.state.page == R_PAGES.PROXY_PAGE ? (
              <Table
                className="tableClass"
                columns={this.columns}
                dataSource={this.state.proxy_map_dhcp}
              />) : null
            }

            {this.state.page == R_PAGES.PROXY_EDIT_PAGE ? (<ProxyEditPage />) : null}

          </Card.Body>
        </Card.Body>
        <Card.Footer>
          <Card.Text>
            {this.state.page == R_PAGES.SERVER_LOGIN ? (<Button variant="success" size="lg" onClick={this.OnLogin}>Login</Button>) : null}
            {this.state.page == R_PAGES.ROUTER_LOGIN ? (<Button variant="success" size="lg" onClick={this.OnRouterLogin}>Pair</Button>) : null}
            {this.state.page == R_PAGES.PROXY_PAGE ? (<>
              <Button variant="primary" size="lg" onClick={this.EditProxy}>EditProxy</Button>
              <Button variant="success" size="lg" onClick={this.GetProxyMap}>ReloadProxy</Button>
            </>) : null}

            {this.state.page == R_PAGES.PROXY_EDIT_PAGE ? (<>
              <Button variant="success" size="lg" onClick={this.SaveProxy}>SaveProxy</Button>
              <Button variant="dark" size="lg" onClick={this.CancelEditProxy}>Cancel</Button>

            </>) : null}

            <Card.Body>
              <Alert key={this.state.alert.variant} variant={this.state.alert.variant}>
                ServerState: {this.state.alert.text}!
              </Alert>
            </Card.Body>
          </Card.Text>
          {<Button onClick={this.UpdateServer}>UpdateServer</Button>}
        </Card.Footer>

      </Card>
    );
  }
}
