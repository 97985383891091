import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Auth from './views/AuthPage';
import {MainPage} from './views/MainPage';
import IsLoggedIn from '../src/services/utils/isLoggedIn';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route
            path="/main"
            element={
              <IsLoggedIn>
                <MainPage />
              </IsLoggedIn>
            }
          />
        </Routes>
      </div>
    );
  }
}
