import { ServiceState } from "./api.interfaces";
import { ApiServiceProxy } from "./gql";


export class ApiService{

    private _state: any;
    private gql_api: ApiServiceProxy;
    
    constructor(){
        this._state = {
            is_logged: false,
            server_status: ServiceState.UNKNOWN,
            gql: {
                ip: '',
                email: '',
                token: '',
                is_connected: false
            },
            proxy_map: [],
            proxy_edit: []
        }

        this.gql_api = new ApiServiceProxy();
    }

    public CurrState(){
        return this._state;
    }

    public async UpdateCurrState(){
        await this.UpdateStatus();
        return this._state;
    }

    public async ServerIsLogin(){
        if(this._state.is_logged === true){
            if(this._state.gql.is_connected === false){
                const result = await this.gql_api.init(this._state.gql.ip, this._state.gql.token);
                if(result === true){
                    this._state.gql.is_connected = true;
                } else{
                    this._state.gql.is_connected = false;
                    this._state.is_logged = false;
                }
            }
            return true;
        }

        return false;
    }

    public async OnServerLogin(email: any, token: any, server_ip:any){

        const _exit = await this.ServerIsLogin();
        if(_exit === true){
            return this._state.gql.is_connected;
        }


        this._state.gql.ip = server_ip;
        this._state.gql.email = email;
        this._state.gql.token = token;

        const result = await this.gql_api.init(server_ip, token);

        if(result === true){
            this._state.is_logged = true;
            this._state.gql.is_connected = true;
        }

        return this._state.gql.is_connected
    }

    public async UpdateStatus(){
        //console.log(this._state)
        const _exit = await this.ServerIsLogin();
        if(_exit != true){
            this._state.server_status = ServiceState.SERVER_UNREABLE;
            return;
        }

        const curr_state = await this.gql_api.CurrentState();
        this._state.server_status = curr_state;
        return this._state.server_status;
    }

    public async GetProxyMap(){
        this._state.proxy_map = await this.gql_api.GetProxyMap();
        return this._state.proxy_map;
    }

    public async GetServerProxy(){

        const curr = await this.gql_api.GetProxy();
        this.SetProxy(curr);

        return this._state.proxy_edit;
    }

    public GetProxy(){
        //this._state.proxy_edit = await this.gql_api.GetProxy();
        return this._state.proxy_edit;
    }

    public async GetWGCredentials(User: string, Password: string, IpAddress: string){
        return await this.gql_api.GetWGCredentials(IpAddress, User, Password);
    }

    public async SetClientPeer(publicKey:string, allowedIPs:string){
        await this.gql_api.SetClientPeer(publicKey, allowedIPs);
    }

    public async UpdateServer(){
        await this.gql_api.UpdateServer();
    }

    public SetProxy(proxy: any){

        this._state.proxy_edit = [];

        for(var i of proxy){
            this._state.proxy_edit.push({
                host: i.host,
                mac: i.mac,
                password: i.password,
                port: i.port,
                type: i.type,
                username: i.username,
            })
        }
    }
    public async UpdateProxyMap(){
        //this._state.proxy_edit = proxy;
        return await this.gql_api.UpdateProxyMap(this._state.proxy_edit);
    }

}

const ApiContext = new ApiService();
export { ApiContext };