import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserStorage } from '../api.interfaces';

export default function IsLoggedIn({ children }: { children: JSX.Element }) {
  if (localStorage.user) {
    const _user:UserStorage = JSON.parse(localStorage.user);
    const token: string = _user.server.token;
    if (!token) {
      return <Navigate to="/" />;
    }
  }

  return children;
}
