import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Magic } from 'magic-sdk';
import { apiServiceAuth } from '../services/api.auth';
import GetUserStorage from '../services/utils/user_storage';

export default class Auth extends Component {
  public magic: any;
  public timer: any;
  public state = {
    email: '',
  };

  constructor(props: any) {
    super(props);
    this.state = { email: '' };
    this.magic = null;
    this.timer = null;

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getToken = this.getToken.bind(this);
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  async writeToLocal(email: string, token: string, did: string): Promise<void> {

    var Storage = GetUserStorage();
    if(Storage === null){
      Storage = {
        did,
        expiry: new Date().getTime() + 1000 * 3600 * 3,
        server:{
          token,
          email,
          server_ip: ''
        },
        router:{
          wgc:{
            endpoint: '',
            key: '',
            port: ''
          },
          mkc:{
            ip: '',
            login: '',
            pass: ''
          }
        }
      }
    } else {
      Storage.did = did;
      Storage.expiry = new Date().getTime() + 1000 * 3600 * 3;
      Storage.server.token = token;
      Storage.server.email = email;
    }

    localStorage.setItem(
      'user',
      JSON.stringify(Storage)
    );
    location.href = '/main';
  }

  async getToken(): Promise<void> {
    let token = await this.magic.user.getIdToken({ lifespan: 3600 });
    if (token) {
      clearInterval(this.timer);
      let did = await apiServiceAuth.whoAmI(token);
      this.writeToLocal(this.state.email, token, did);
    }
  }

  async login(): Promise<void> {
    this.magic = new Magic(process.env.REACT_APP_PUBLIC_KEY!);
    await this.magic.auth.loginWithMagicLink({
      email: this.state.email,
    });
    this.timer = setInterval(this.getToken, 1000);
  }


  componentDidMount() {
    //disable_login
    this.writeToLocal("<user_email>", "<token>", "<did>")
  }

  render() {
    return (
      <Card className="text-center">
        <Card.Body>
          <Card.Title>Enter your email</Card.Title>
          <Card.Text>
            <Form.Control
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.onChange}
            />
          </Card.Text>
          <div className="d-grid gap-2">
            <Button variant="success" size="lg" onClick={this.login}>
              Login
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
