import { ApolloClient } from '@apollo/client/core';
import makeApolloClient from './utils/makeApolloClient';
import gql from 'graphql-tag';

class ApiServiceAuth {
  protected client: ApolloClient<any>;

  constructor(client: ApolloClient<any>) {
    this.client = client;
  }

  WHO_AM_I = gql`
    query WhoAmI($token: String!, $microservice: String!) {
      whoAmI(token: $token, microservice: $microservice) {
        id
      }
    }
  `;

  whoAmI = async (token: string) => {
    try {
      const result = await this.client.query({
        query: this.WHO_AM_I,
        variables: {
          token,
          microservice: 'mmo',
        },
      });
      return result.data.whoAmI.id;
    } catch (err) {
      console.log('ERROR:', err);
    }
  };
}

const client = makeApolloClient(process.env.REACT_APP_AUTH_URL!);
const apiServiceAuth = new ApiServiceAuth(client);
export { apiServiceAuth };
