export interface InputUserServer{
    email: string;
    token: string;
    server_ip: string;
};


export interface WGCredentials{
    endpoint: string;
    key: string;
    port: string;
}

export interface MikrotikCredentials{
    ip: string;
    login: string;
    pass: string;
}

export interface InputMikrotikPair{
    wgc: WGCredentials;
    mkc: MikrotikCredentials;
}

export interface WgInterface{
    publicKey:string;
    allowedIPs: string;
}

export interface ResponceMikrotikPair{
    interface?: WgInterface;
    state: any;
}

export interface UserStorage{
    did: string,
    expiry: any,
    server: InputUserServer;
    router: InputMikrotikPair;
}

export const ServiceState = {

    SERVER_UNREABLE: 'SERVER_UNREABLE',

    UNKNOWN: 'UNKNOWN',
    CONFIGURE_ROUTER: 'CONFIGURE_ROUTER',
    PING_ERROR: 'PING_ERROR',
    EMPTY_PROXY: 'EMPTY_PROXY',
    ERROR_SYNC_ROUTER: 'ERROR_SYNC_ROUTER',
    ERROR_SYNC_WIREGUARD: 'ERROR_SYNC_WIREGUARD',
    ERROR_SYNC_REDSOCKS: 'ERROR_SYNC_REDSOCKS',
    PROCESS_SYNC_WIREGUARD: 'PROCESS_SYNC_WIREGUARD',
    READY: 'READY'
};
