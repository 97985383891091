import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions } from '@apollo/client/core';
import fetch from 'cross-fetch';
import gql from 'graphql-tag';
import { ServiceState } from './api.interfaces';

function getHttpLink(httpURL: string, token: string) {
  return new HttpLink({
    uri: httpURL,
    fetch,
    headers: token ? { Authorization: token } : {},
  });
}

export function MakeApolloClient(httpURL: string, token: string = '') {
  const httpLink: HttpLink = getHttpLink(httpURL, token);
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  
  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });

  return client;
}


export class ApiServiceProxy {

  private TOKEN = 'WyIweDMyYmIwNTZkOGIwNDRiMmIxYjI4NWU0MDEzZDJmZTgwZjc1MjY5NTVmOTgzY2UxMDk4ZjQ1YmM1ZGUxZjNlZGMwZjIxYTY2OTVmNDAzZGM4ZTViZTEwZGFiZGE0NWVkMTg4NzFlNjM1MzExODk2OTg0ZDE2YzZjMTA0MjVjYzE0MWMiLCJ7XCJpYXRcIjoxNjQ5MjI4MTAyLFwiZXh0XCI6MTY5OTIzMTcwMixcImlzc1wiOlwiZGlkOmV0aHI6MHhEZTBhQWI0Njc4RWQ2N2Q4MTlEMTBCOTYwZDM0MTgyY0VGY2VGMzBmXCIsXCJzdWJcIjpcIjMxaThhUmR2Zm90ZVFhMWRLdm0xREs3V1ZFYzJDRjFVSGJCZTF3VU5Vakk9XCIsXCJhdWRcIjpcIjFfVFJOQVdiVHJxNHlPNWhDNWp4U3pieGlJc3ZzM2tVUHhXYUNoX2RIQ2M9XCIsXCJuYmZcIjoxNjQ5MjI4MTAyLFwidGlkXCI6XCIyNGRhOWJlYS03ODc2LTQ2MTQtYWVlMC1hMGEyY2JiNjA2ODVcIixcImFkZFwiOlwiMHgxNjdmOGRmMTljNjQ2OWZjNmQzMzBlYzgzZDIyZmEzMDNhZWE1ZjljNjYxOTIyYzFiMmE2MTY3MDYxZjEzNzMwMGYxNTJjMjU3N2YwMjg0NmFkNGMxMWZhZGVjMTM4Zjc5NWEwMTI3ZTViY2NhODQ5NTI2MzBkOGJiNmUzYTk0OTFjXCJ9Il0=';

  public client: ApolloClient<any>;
  public wclient: any;

  CURENT_STATE = gql`
        query CurrentState {
            CurrentState 
        }`

  GET_WG_CREDENTIALS = gql`
      query GetWGCredentials($credentials: MikrotikCredentialsInput!) {
        GetWGCredentials(credentials: $credentials) {
          endpoint
          key
          port
        }
      }
    `

  SET_CLIENT_PEER = gql`mutation SetClientPeer($peer: WGPeerInput!) {
    SetClientPeer(peer: $peer)
  }`

  UPDATE_SERVER = gql`mutation UpdateServer {
    UpdateServer 
  }`

  UPDATE_PROXY_MAP = gql`mutation Mutation($proxy: [ProxyMapInput]!) {
    UpdateProxyMap(proxy: $proxy)
  }`

  GET_PROXY_MAP = gql`query GetProxyMap {
    GetProxyMap {
        is_proxed
        status
        console_ip
        mac
        dynamic
        lastSeen
        host
        port
        type
        username
        password
    }
  }`

GET_PROXY = gql`query GetProxy {
  GetProxy {
      mac
      host
      port
      type
      username
      password
  }
}`

  UpdateServer = async() => {
    try {
      const result = await this.client.mutate({
        mutation: this.UPDATE_SERVER,
      });
      return result.data.UpdateServer;

    } catch (err) {
      console.log('ERROR UpdateServer:', err);
    }
    return ""
  }

  SetClientPeer = async(publicKey:string, allowedIPs:string) => {
    try {
      const result = await this.client.mutate({
        mutation: this.SET_CLIENT_PEER,
        variables: {
          "peer": {
            publicKey,
            allowedIPs
          }
        },
      });
      //console.log('SetClientPeer:', result.data);

      return result.data.SetClientPeer;

    } catch (err) {
      console.log('ERROR SetClientPeer:', err);
    }
    return ""
  }


  UpdateProxyMap = async(proxy:any) => {
    try {
      console.log(proxy)
      const result = await this.client.mutate({
        mutation: this.UPDATE_PROXY_MAP,
        variables: {
          "proxy": proxy
        },
      });
      return result.data.UpdateProxyMap;

    } catch (err) {
      console.log('ERROR UpdateProxyMap:', err);
    }
    return -1
  }

  GetWGCredentials = async (ip: string, login: string, pass: string) => {
    try {
      const result = await this.client.query({
        query: this.GET_WG_CREDENTIALS,
        variables: {
          "credentials": {
            login,
            pass,
            ip
          }
        },
      });
      const { endpoint, key, port } = result.data.GetWGCredentials;
      return { endpoint, key, port }

    } catch (err) {
      console.log('ERROR GetWGCredentials:', err);
    }

    return { endpoint: "", key: "", port: "" }
  }

  GetProxyMap = async () => {
    try 
    {
      const result = await this.client.query({ query: this.GET_PROXY_MAP });
      console.log('GetProxyMap:', result);
      return result.data.GetProxyMap;

    } catch (err) {
      console.log('ERROR GetProxyMap:', err);
    }
    return []
  }

  GetProxy = async () => {
    try 
    {
      const result = await this.client.query({ query: this.GET_PROXY });
      //console.log('GetProxy:', result);
      return result.data.GetProxy;

    } catch (err) {
      console.log('ERROR GetProxy:', err);
    }
    return []

  }

  CurrentState = async () => {
    try {
      const result = await this.client.query({
        query: this.CURENT_STATE,
      });
      //console.log('CurrentState:', result.data.CurrentState);
      return result.data.CurrentState;
    } catch (err) {
      console.error('ERROR CURENT_STATE:', err);
    }

    return ServiceState.UNKNOWN;
  }

  CurrentStatePing = async () => {
    try {

      const result = await this.client.query({
        query: this.CURENT_STATE,
      });

      console.log('CurrentStatePing:', result.data.CurrentState);

      return true;
    } catch (err) {
      console.error('ERROR CURENT_STATE:', err);
    }

    return false;
  }

  public async init(server_ip: string, token: string = '') {
    try {
      const gql_url = `${server_ip}/graphql`;
      const user_token = token != "" ? token : this.TOKEN;
      this.client = MakeApolloClient(gql_url, user_token);

      return await this.CurrentStatePing()

    } catch (error: any) {
      console.error("error:", error)
    }

    return false;
  }
}