import React from 'react';
import { Space, Table, Tag } from 'antd';
export const ProxyColumns = [
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render(status:any) {

            let color = 'geekblue';

            if(status == 'active'){
                color = 'green'
            }

            if(status == 'disconnected'){
                color = 'red'
            }

            return (<Tag color={color} key={status}>
                {status}
            </Tag>);
        },

       /* children: [
            {
                title: 'status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'is_proxed',
                dataIndex: 'is_proxed',
                key: 'is_proxed',
            },
            {
                title: 'dynamic',
                dataIndex: 'dynamic',
                key: 'dynamic',
            },
            {
                title: 'lastSeen',
                dataIndex: 'lastSeen',
                key: 'lastSeen',
            },

        ]*/
    },
    {
        title: 'Device',
        dataIndex: 'console_ip',
        key: 'console_ip',
    },
    {
        title: 'Mac',
        dataIndex: 'mac',
        key: 'mac',
    },
    {
        title: 'Proxy',
        children: [
            {
                title: 'type',
                dataIndex: 'type',
                key: 'type',
            },

            {
                title: 'host',
                dataIndex: 'host',
                key: 'host',
            },

            {
                title: 'port',
                dataIndex: 'port',
                key: 'port',
            },

            {
                title: 'username',
                dataIndex: 'username',
                key: 'username',
            },

            {
                title: 'password',
                dataIndex: 'password',
                key: 'password',
            },

        ]
    }
]


export const ProxyEditColumns = [
    {
        title: 'Mac',
        dataIndex: 'mac',
        key: 'mac',
        editable: true,
    },
    {
        title: 'Proxy',
        children: [
            {
                title: 'type',
                dataIndex: 'type',
                key: 'type',
                editable: true,
            },

            {
                title: 'host',
                dataIndex: 'host',
                key: 'host',
                editable: true,
            },

            {
                title: 'port',
                dataIndex: 'port',
                key: 'port',
                editable: true,
            },

            {
                title: 'username',
                dataIndex: 'username',
                key: 'username',
                editable: true,
            },

            {
                title: 'password',
                dataIndex: 'password',
                key: 'password',
                editable: true,
            },

        ]
    }
]