import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table } from 'antd';

import { ApiContext } from '../services/api.service';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()!;
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default function App() {

  var data = [];
  var _count = 0;
  for (let i of ApiContext.GetProxy()) {
    data.push({ ...i, key: _count });
    ++_count;
  }

  const [dataSource, setDataSource] = useState(data);
  const [count, setCount] = useState(data.length);

  const handleDelete = (_: any, record: { key: any; }) => {
    console.log("Delete: ", record)
    const newData = dataSource.filter((item) => item.key !== record.key);
    setDataSource(newData);

    ApiContext.SetProxy(newData);
  };

  const defaultColumns = [
    {
      title: 'Mac',
      dataIndex: 'mac',
      key: 'mac',
      editable: true,
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      editable: true,
    },

    {
      title: 'host',
      dataIndex: 'host',
      key: 'host',
      editable: true,
    },

    {
      title: 'port',
      dataIndex: 'port',
      key: 'port',
      editable: true,
    },

    {
      title: 'username',
      dataIndex: 'username',
      key: 'username',
      editable: true,
    },

    {
      title: 'password',
      dataIndex: 'password',
      key: 'password',
      editable: true,
    },

    // ]
    // },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_: any, record: any) =>
        //console.log("Render:", _, record);
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(_, record)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      //...dataSource[0]
      mac: 'FF:FF:FF:FF:FF:FF',
      type: 'socks5',
      host: '127.0.01',
      port: '8080',
      username: 'login',
      password: 'password'

    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);

    //console.log()
    ApiContext.SetProxy(dataSource);
  };

  const handleSave = (row: { key: any; }) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);

    console.log(newData)
    ApiContext.SetProxy(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add a row
      </Button>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
}

//export default App;