import { UserStorage } from "../api.interfaces";

export default function GetUserStorage(): UserStorage | null {
  if (localStorage.user) {
    const _user:UserStorage = JSON.parse(localStorage.user);
    return _user;
  }

  return null;
}
